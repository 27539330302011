<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-autocomplete
        v-model="currentUserId"
        :items="$store.getters['project/getListOfUser']"
        :filter="userFilter"
        item-value="id"
        dense
        outlined
        :item-text="(item) => `${item.full_name ? item.full_name : ''} ${item.email}`"
        label="Utilisateurs"
        @change="setCurrentUser"
        return-object
      >
        <template slot="no-data">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Pas de utilisateur</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="6">
      <v-select
        v-model="currentProjectId"
        :items="$store.getters['project/getList']"
        :item-text="(item) => `${item.address} ${item.city}`"
        item-value="id"
        label="Projets"
        dense
        outlined
        @change="setCurrentProject"
      >
        <template slot="no-data">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Pas de projet</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ProjectSwitcher",
  data() {
    return {
      currentProjectId: this.$store.getters["project/getCurrentProjectId"],
      currentUserId: this.$store.getters["project/getCurrentUserId"],
    };
  },
  watch: {
    "$store.state.project.currentProject"(newVal) {
      this.currentProjectId = newVal?.id || null;
    },
    "$store.state.project.currentUserId"(newVal) {
      this.currentUserId = newVal || null;
    },
  },
  methods: {
    setCurrentProject(projectId) {
      this.$store.commit("project/SET_CURRENT_PROJECT_ID", projectId);
      this.$emit("setCurrentProject");
    },
    setCurrentUser(user) {
      this.$store.commit("project/SET_CURRENT_USER_ID", user.id);
      this.$store.commit("project/SET_CURRENT_USER", user);
      
      const project = this.$store.getters['project/getList'].find(project => project.owner_id === user?.id)
      this.$store.commit("project/SET_CURRENT_PROJECT_ID", project?.id || null)
    },
    userFilter(item, queryText, itemText) {
      const email = item.email.toLowerCase();
      const searchText = queryText.toLowerCase();

      return email.indexOf(searchText) > -1;
    },
  },
};
</script>

<style>
</style>